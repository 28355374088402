//@flow
import * as React from "react"
//$FlowFixMe
import '../styles/Devices.scss';
import { Row, Col } from 'reactstrap';

type Props = {
	screenshot: any,
	device?: 'iphone-14-pro' | 'iphone-14' | 'ipad-pro',
	landscape?: boolean,
	centered?: boolean,
	size?: 'lg' | 'md' | 'sm' | 'xs',
	color?: 'blue' | 'silver' | 'black' | 'gold',
	rotate?: number
}

const Device = ({ screenshot, device, landscape, centered, size, color, rotate }: Props): React.Node => {

	const _device = device || 'iphone-14-pro';

	let classes = "device device-" + _device;
	if (landscape) {
		classes += " device-landscape";
	}
	if (size) {
		classes += " device-" + size
	}
	if (color) {
		classes += " device-" + color
	}
	let boxClass = (centered) ? "justify-content-center" : "";

	let style;

	if (rotate) {
		style = { rotate: rotate + 'deg' }
	}

	return (
		<Row className={boxClass}>
			<Col xs="auto">
				<div className={classes} style={style}>
					<div class="device-frame">
						<img class="device-screen" src={screenshot} />
					</div>
					<div class="device-stripe"></div>
					<div class="device-header"></div>
					<div class="device-sensors"></div>
					<div class="device-btns"></div>
					<div class="device-power"></div>

				</div>
			</Col>
		</Row>

	)
}

export default Device