import * as React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useIntl, Link } from "gatsby-plugin-intl"
import Markdown from 'markdown-to-jsx';

import '../../styles/Whereisthat.scss';

import Navigation from '../../components/Navigation';
import Footer from '../../components/Footer';
import Segment from '../../components/Segment';
import SEO from '../../components/seo';
import Device from "../../components/Device";
import FormattedMessage from "../../components/FormattedMessage";

import WhereisthatLogo from '../../images/whereisthat_logo.png';
import WhereIsThatScreenPhoneMultiplayer from '../../images/whereisthat/screen_phone_multiplayer.png';
import WhereIsThatScreeniPadMain from '../../images/whereisthat/screen_landing_ipad_main.png';
import WhereIsThatScreeniPadLearn from '../../images/whereisthat/screen_ipad_learn.png';
import WhereIsThatScreeniPadQuiz from '../../images/whereisthat/screen_ipad_quiz.png';
import WhereIsThatScreeniPadPins from '../../images/whereisthat/screen_ipad_pins.png';
import WhereIsThatScreeniPadProgress from '../../images/whereisthat/screen_ipad_progress.png';

import AppstoreButtonEN from '../../images/appstore_button_en.png';
import GooglePlayButtonEN from '../../images/google-play-badge-en.png';
import AppstoreButtonDE from '../../images/appstore_button_de.png';
import GooglePlayButtonDE from '../../images/google-play-badge-de.png';


const IndexPage = () => {

	const intl = useIntl();

	const AppstoreButton = (intl.locale === 'de') ? AppstoreButtonDE : AppstoreButtonEN;
	const GooglePlayButton = (intl.locale === 'de') ? GooglePlayButtonDE : GooglePlayButtonEN;


	return (
		<div className="whereisthat">
			<SEO title={intl.formatMessage({ id: "app.whereisthat.title" })} />
			<Navigation />

			<Segment size="lg">
				<Row className="justify-content-center mb-4">
					<Col xs={12} md={10} lg={8}>

						<h2 className="text-center"><FormattedMessage id="app.whereisthat.title" /></h2>
						<h3 className="text-center"><FormattedMessage id="landing.whereisthat.subtitle" defaultMessage="A geography quiz for iOS and Android." /></h3>
						<p className="lead text-center"><FormattedMessage id="landing.whereisthat.lead" defaultMessage="Downloaded by over 10 million users. Top chart positions. Featured by Apple." /></p>
					</Col>
				</Row>

				<Device centered rotate={268} size="lg" device="ipad-pro" landscape screenshot={WhereIsThatScreeniPadMain} />

				<Row className="justify-content-center mt-5">
					<Col lg={8}>
						<p className="text-center"><FormattedMessage id="landing.whereisthat.desc" defaultMessage="One of the top geography e-learning apps to learn countries, capitals, states, cities and much more with a scientifially proven algorithm. With the integrated online multiplayer, you can play with your friends all over the world." /></p>
						<p className="lead text-center"><FormattedMessage id="landing.whereisthat.cta" defaultMessage="Download and try for free. Available on iOS and Android." /></p>
					</Col>
				</Row>
				<DownloadButtons centered locale={intl.locale} />




			</Segment>
			<Segment background="primary" large>
				<Row className="justify-content-center mb-4">
					<Col xs={12} md={10} lg={8}>
						<h2 className="text-center"><FormattedMessage id="app.whereisthat.learn.title" defaultMessage="Learn geography" /></h2>
						<h3 className="text-center"><FormattedMessage id="app.whereisthat.learn.subtitle" defaultMessage="Countries, capitals, states, cities, sights and more." /></h3>
						<p className="lead text-center"><FormattedMessage id="app.whereisthat.learn.lead" defaultMessage="Quickly improve your knowledge with proven scientific methods" /></p>
					</Col>
				</Row>

				<Device centered size="lg" device="ipad-pro" landscape screenshot={WhereIsThatScreeniPadLearn} />

				<Row className="justify-content-center mt-5">
					<Col lg={8}>
						<p className="text-center"><FormattedMessage id="app.whereisthat.learn.desc" defaultMessage="The geography learning mode is based on a spaced repetition algorithm. Areas that need improvement are identified and prioritized to ensure a long term learning success." /></p>
					</Col>
				</Row>

			</Segment>
			<Segment background="light" large>
				<Row className="justify-content-center align-items-center mb-4">
					<Col xs={12} md={7} lg={6}>
						<h2 className="text-center"><FormattedMessage id="app.whereisthat.multiplayer.title" defaultMessage="Online multiplayer" /></h2>
						<h3 className="text-center"><FormattedMessage id="app.whereisthat.multiplayer.subtitle" defaultMessage="Challenge your friends all around the world" /></h3>
						<p className="lead text-center"><FormattedMessage id="app.whereisthat.multiplayer.lead" defaultMessage="Play in real time with live games or asynchronous with a rounds game. All categories are available for free in online games." /></p>
					</Col>
					<Col xs={12} md={3} lg={4}>
						<Device centered size="md" rotate={3} device="iphone-14" screenshot={WhereIsThatScreenPhoneMultiplayer} />
					</Col>
				</Row>


			</Segment>

			<Segment background="primary" size="lg">
				<Row className="justify-content-center mb-4">
					<Col xs={12} md={10} lg={8}>
						<h2 className="text-center"><FormattedMessage id="app.whereisthat.quiz.title" defaultMessage="Quiz Mode" /></h2>
						<h3 className="text-center"><FormattedMessage id="app.whereisthat.quiz.subtitle" defaultMessage="The ideal game mode to get started with a new category." /></h3>
						<p className="lead text-center"><FormattedMessage id="app.whereisthat.quiz.lead" defaultMessage="Pick the right answer in a multiple choice quiz." /></p>
					</Col>
				</Row>

				<Device centered size="lg" device="ipad-pro" landscape screenshot={WhereIsThatScreeniPadQuiz} />

				<Row className="justify-content-center mt-5">
					<Col lg={8}>
						<p className="text-center"><FormattedMessage id="app.whereisthat.quiz.desc" defaultMessage="In the default mode, you have to select the right location on the map. When starting with a new category or area of the world, this can be challenging. In quiz mode you get 4 answer options and just have to select the right one." /></p>
					</Col>
				</Row>

			</Segment>

			<Segment background="light" size="lg">
				<Row className="justify-content-center mb-4">
					<Col xs={12} md={10} lg={8}>
						<h2 className="text-center"><FormattedMessage id="app.whereisthat.pins.title" defaultMessage="Pin Mode" /></h2>
						<h3 className="text-center"><FormattedMessage id="app.whereisthat.pins.subtitle" defaultMessage="For capitals, cities and sights." /></h3>
						<p className="lead text-center"><FormattedMessage id="app.whereisthat.pins.lead" defaultMessage="Select the right location out of 10 or 30 options." /></p>
					</Col>
				</Row>

				<Device centered size="lg" device="ipad-pro" landscape screenshot={WhereIsThatScreeniPadPins} />

				{/*<Row className="justify-content-center mt-5">
					<Col lg={8}>
						<p className="text-center"><FormattedMessage id="app.whereisthat.pins.desc" defaultMessage="Increase the difficulty by using the pin mode." /></p>
					</Col>
	</Row>*/}

			</Segment>

			<Segment size="lg" background="primary">
				<Row className="justify-content-center mb-4">
					<Col xs={12} md={10} lg={8}>
						<h2 className="text-center"><FormattedMessage id="app.whereisthat.progress.title" defaultMessage="Track your progress" /></h2>
						<h3 className="text-center"><FormattedMessage id="app.whereisthat.progress.subtitle" defaultMessage="Detailed stats for each location" /></h3>
						<p className="lead text-center"><FormattedMessage id="app.whereisthat.progress.lead" defaultMessage="Try to reach 100% for each category." /></p>
					</Col>
				</Row>

				<Device centered size="lg" device="ipad-pro" screenshot={WhereIsThatScreeniPadProgress} />

				{/*<Row className="justify-content-center mt-5">
					<Col lg={8}>
						<p className="text-center"><FormattedMessage id="app.whereisthat.pins.desc" defaultMessage="Increase the difficulty by using the pin mode." /></p>
					</Col>
	</Row>*/}

			</Segment>

			<Segment size="lg" background="light">
				<h2 className="text-center"><FormattedMessage id="app.whereisthat.categories.title" /></h2>
				<h3 className="text-center mb-5"><FormattedMessage id="app.whereisthat.categories.text" /></h3>
				<CategoryElement title={intl.formatMessage({ id: "app.whereisthat.categories.countries.title" })} categories={intl.formatMessage({ id: "app.whereisthat.categories.countries.categories" })} />
				<CategoryElement title={intl.formatMessage({ id: "app.whereisthat.categories.capitals.title" })} categories={intl.formatMessage({ id: "app.whereisthat.categories.capitals.categories" })} />
				<CategoryElement title={intl.formatMessage({ id: "app.whereisthat.categories.states.title" })} categories={intl.formatMessage({ id: "app.whereisthat.categories.states.categories" })} />
				<CategoryElement title={intl.formatMessage({ id: "app.whereisthat.categories.cities.title" })} categories={intl.formatMessage({ id: "app.whereisthat.categories.cities.categories" })} />
				<CategoryElement title={intl.formatMessage({ id: "app.whereisthat.categories.culture.title" })} categories={intl.formatMessage({ id: "app.whereisthat.categories.culture.categories" })} />



			</Segment>
			<Segment background="primary" large>
				<div className="row">
					<div className="col text-center">
						<h2 className="mb-3"><FormattedMessage id="app.whereisthat.cta.title" /></h2>
						<p className="lead"><FormattedMessage id="app.whereisthat.cta.text" /></p>
						<DownloadButtons centered locale={intl.locale} />
						<p className="mt-3"><FormattedMessage id="app.whereisthat.cta.discount" /></p>
					</div>
				</div>
			</Segment>
			<Footer />
		</div >
	);
}

type DownloadButtonProps = {
	locale: string,
	centered?: boolean
}

class DownloadButtons extends React.Component<DownloadButtonProps> {


	render() {
		const { locale, centered } = this.props;
		const className = (centered) ? "justify-content-center" : null;


		const AppstoreButton = (locale === 'de') ? AppstoreButtonDE : AppstoreButtonEN;
		const GooglePlayButton = (locale === 'de') ? GooglePlayButtonDE : GooglePlayButtonEN;

		return (
			<Row className={className}>
				<Col xs="auto" className="mb-2">
					<a href="http://apple.co/1s4yWSp"><img src={AppstoreButton} height={50} /></a>
				</Col>
				<Col xs="auto">
					<a href="http://bit.ly/2reaEGk"><img src={GooglePlayButton} height={50} /></a>
				</Col>
			</Row>
		);
	}
}

class CategoryElement extends React.Component {
	render() {
		return (
			<div className="row category">
				<div className="col-12 col-md-4 title">
					<h4 className="mt-1">{this.props.title}</h4>
				</div>
				<div className="col-12 col-md-8">
					<ul className="categories">
						{this.props.categories.split(';').map((category) => {
							return (
								<li>{category}</li>
							);
						})}
					</ul>
				</div>
			</div>
		);
	}
}

export default IndexPage;
