//@flow
import * as React from 'react';
import { Container } from 'reactstrap';

type Props = {
  background?: string,
  large?: boolean,
  fluidContainer?: boolean,
  children?: React.Node,
  size?: 'sm' | 'md' | 'lg'
}

class Segment extends React.Component<Props> {

  render(): React.Node {
    var className = 'segment';
    if (this.props.background)
      className += ' segment-' + this.props.background;
    if (this.props.size)
      className = className + ' segment-' + this.props.size;
    return (
      <div className={className}>
        <Container fluid={this.props.fluidContainer}>
          {this.props.children}
        </Container>
      </div>
    );
  }
}



export default Segment;
