//@flow
import * as React from "react"
import { graphql } from "gatsby"

import Markdown from 'markdown-to-jsx';
import breaks from 'remark-breaks';
import moment from "moment";

import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import Segment from '../components/Segment';
import Terms from '../components/Terms';

type Props = {
	data: any,
}

export default function TermsPage({ data }: Props): React.Node {


	const { terms } = data;
	return (
		<div className="terms">
			<Navigation />
			<Terms terms={terms} />
			<Footer />
		</div >
	)
}

//$FlowFixMe
export const query = graphql`
query($id: String!) {
	terms(id: { eq: $id }) {
	  projectId	  
	  type
	  title
	  termsTitle
	  sections {
		sectionId
		text
		title
		link
		address
	  }
	}
  }
`