//@flow
import React from "react"
import { graphql } from "gatsby"

import Terms from '../components/Terms';

//$FlowFixMe
export default function TermsPage({ data }) {


	const { terms } = data;
	return <Terms terms={terms} />
}

//$FlowFixMe
export const query = graphql`
query($id: String!) {
	terms(id: { eq: $id }) {
	  projectId	  
	  type
	  title
	  termsTitle
	  sections {
		sectionId
		text
		title
		link
		address
	  }
	}
  }
`