//@flow
import * as React from 'react';
import { Button } from 'reactstrap';
import { useIntl } from "gatsby-plugin-intl"


type Props = {

}

const SocialButtons = (): React.Node => {

	const intl = useIntl();

	return (
		<div className="social-buttons">

			<Button href="mailto:info@jaysquared.com" color="primary" size="lg">
				{intl.formatMessage({ id: 'button.email', defaultMessage: "Send me a message" })}
			</Button>
		</div>
	);
}

export default SocialButtons;