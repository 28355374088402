import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Container, Row, Col } from 'reactstrap';
import { useIntl, Link } from "gatsby-plugin-intl"

import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import Segment from '../components/Segment';
import SEO from '../components/seo';
import SocialButtons from '../components/SocialButtons';

const IndexPage = () => {

	const intl = useIntl();

	return (<div className="terms">
		<SEO title={intl.formatMessage({ id: "page.imprint.title" })} />
		<div className="header">
			<Navigation />
		</div>
		<Segment background="primary">
			<div className="row mb-2">
				<div className="col">
					<h1>{intl.formatMessage({ id: "page.imprint.title" })}</h1>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<p><strong>Jaysquared</strong><br />Till Henrik Jonathan Hillebrand</p>
					<p><strong>{intl.formatMessage({ id: "page.imprint.email.title" })}:</strong> <a href="mailto:jonathan@jaysquared.com">jonathan@jaysquared.com</a><br />
						<strong>{intl.formatMessage({ id: "page.imprint.phone.title" })}:</strong> +49 40 22860759</p>
					<p><strong>{intl.formatMessage({ id: "page.imprint.address.title" })}</strong><br />
						c/o IP-Management #44575<br />
						Ludwig-Erhard-Str. 18<br />
						20459 Hamburg<br />
						Germany</p>
					<p><strong>{intl.formatMessage({ id: "page.imprint.vatid.title" })}:</strong> DE288667235</p>
				</div>
			</div>
		</Segment>
		<Footer />
	</div>
	);
}

export default IndexPage;
