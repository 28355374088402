import React from "react"
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"

const languageName = {
	en: "English",
	de: "Deutsch",
}

const Language = () => {
	return (
		<ul>
			<IntlContextConsumer>
				{({ languages, language: currentLocale }) =>
					languages.map(language => (
						<li><a
							key={language}
							onClick={() => changeLocale(language)}
							className={currentLocale === language ? 'active' : ''}
						>
							{languageName[language]}
						</a></li>
					))
				}
			</IntlContextConsumer>
		</ul>
	)
}

export default Language