//@flow
import React, { useState, type Node } from 'react';
import { Button, InputGroup, Input, Alert } from 'reactstrap';
import addToMailchimp from 'gatsby-plugin-mailchimp'
import FormattedMessage from "./FormattedMessage"
import { useIntl } from "gatsby-plugin-intl"

type Props = {
    id: string
}

const States = {
    IDLE: 'idle',
    SUCCESS: 'success',
    ERROR: 'error'
}

type State = $Values<typeof States>

function NewsletterSignup({ id }: Props): Node {

    const [isLoading, setIsLoading] = useState(false);
    const [state, setState] = useState(States.IDLE);
    const [error, setError] = useState(null);
    const [email, setEmail] = useState('');

    const intl = useIntl();



    const addEmailAddress = async (e) => {

        e.preventDefault();

        setIsLoading(true);
        setState(States.IDLE);
        setError(null);

        try {

            await addToMailchimp(email);

            /*await firebase.firestore().collection("MailingList").add({
                id: 'test',
                email,
                timestamp: firebase.firestore.FieldValue.serverTimestamp()
            });*/

            console.log('addEmailAddress:success');
            setState(States.SUCCESS);
        } catch (error) {
            console.log(error.message);
            setError(error);
            setState(States.ERROR);
        }

        setIsLoading(false);
    }


    return (
        <form onSubmit={addEmailAddress}>
            <InputGroup>
                <Input type="text" name="email" onChange={(e) => setEmail(e.target.value)} value={email} placeholder={intl.formatMessage({ id: "placeholder.email", defaultMessage: "Your email address" })} disabled={isLoading} />
                <Button color='primary' disabled={isLoading}>{isLoading ? <FormattedMessage id="button.signup.loading" defaultMessage="Signing you up" /> : <FormattedMessage id="button.signup" defaultMessage="Keep me updated" />}</Button>
            </InputGroup>
            {state === States.ERROR && error != null && <Alert color="danger" className="mt-3 p-1">{error.message}</Alert>}
            {state === States.SUCCESS && <Alert color="success" className="mt-3 p-1"><FormattedMessage id="landing.signup.success" defaultMessage="You are signed up!" /></Alert>}
        </form>
    );
}

export default NewsletterSignup;