//@flow
import * as React from "react"

import { Row, Col } from "reactstrap";

import SEO from "../components/seo"
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import Segment from '../components/Segment';

const NotFoundPage = (): React.Node => (
  <div>
    <SEO title="404: Not found" />
    <Navigation />
    <Segment>
      <Row>
        <Col>
          <h1>404: Not Found</h1>
          <p className="lead">You just hit a route that doesn&#39;t exist... the sadness.</p>
        </Col>
      </Row></Segment>
  </div>
)

export default NotFoundPage
