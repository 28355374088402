//@flow
import * as React from 'react';
import { FormattedMessage } from "gatsby-plugin-intl"

type Props = {
    id: string,
    defaultMessage: string
}

function JSFormattedMessage({ id, defaultMessage }: Props): React.Node {

    return <FormattedMessage
        id={id}
        defaultMessage={defaultMessage}
        values={{
            strong: (chunks) => <strong>{chunks}</strong>,
            brand: (chunks) => <span class="brand-primary">{chunks}</span>,
            br: <br />
        }} />
}

export default JSFormattedMessage;