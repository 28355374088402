//@flow
import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Container, Row, Col, List, ListInlineItem, Button } from 'reactstrap';
import { useIntl, Link } from "gatsby-plugin-intl"
import Markdown from 'markdown-to-jsx';

import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import Segment from '../components/Segment';
import SEO from '../components/seo';
import SocialButtons from '../components/SocialButtons';
import Device from "../components/Device";
import FormattedMessage from "../components/FormattedMessage";
import NewsletterSignup from "../components/NewsletterSignup";

import WhereisthatLogo from '../images/whereisthat_logo.png';
import AppstoreButtonEN from '../images/appstore_button_en.png';
import GooglePlayButtonEN from '../images/google-play-badge-en.png';
import AppstoreButtonDE from '../images/appstore_button_de.png';
import GooglePlayButtonDE from '../images/google-play-badge-de.png';
import EventsMockup from '../images/jaysquared_events_home.png';
import WeddingDuelMockup from '../images/landing/Mockup_WeddingDuel.png';
import HonuaScreen1DE from '../images/honua/screen_1_de.jpg';
import WhereIsThatScreenLanding from '../images/whereisthat/screen_landing_portrait.png';
import WhereIsThatScreeniPadMain from '../images/whereisthat/screen_landing_ipad_main.png';
import IllustrationMappingEngine from '../images/landing/illustration_mapping_engine.png';


const IndexPage = (): React.Node => {

    const intl = useIntl();

    const AppstoreButton = (intl.locale === 'de') ? AppstoreButtonDE : AppstoreButtonEN;
    const GooglePlayButton = (intl.locale === 'de') ? GooglePlayButtonDE : GooglePlayButtonEN;

    return (
        <div className="home">
            <SEO title={intl.formatMessage({ id: "site.siteMetadata.title" })} />
            <Navigation />
            <Segment>
                <Row className="align-items-center">
                    <Col xs={12} md={7} lg={7}>
                        <h1>
                            <FormattedMessage
                                id="page.landing.title"
                                defaultMessage="Leading expert for geography games"
                            />
                        </h1>
                        <h3 className="mt-3">
                            <FormattedMessage
                                id="page.landing.lead_1"
                                defaultMessage="Where is that? is a geography quiz app loved by over 10 million users all over the world."
                            />
                        </h3>
                        <h3 className="mt-3">
                            <FormattedMessage
                                id="page.landing.lead_2"
                                defaultMessage="Jaysquared Events is the white label solution for hosting a live geography quiz."
                            />
                        </h3>
                        <h3 className="mt-3 mb-4">
                            <FormattedMessage
                                id="page.landing.lead_3"
                                defaultMessage="WeddingDuel brings the live geography quiz experience to your wedding party."
                            />
                        </h3>
                        <SocialButtons />
                    </Col>
                    <Col md={5} lg={5} className="d-none d-md-block">
                        <Device centered size="md" rotate={4} screenshot={WhereIsThatScreenLanding} device="iphone-14-pro" color="silver" />
                    </Col>
                </Row>
            </Segment>
            <Segment background="primary" size='sm'>
                <Row className="align-items-center">
                    <Col lg={4} className="d-none d-lg-block">
                        <img src={IllustrationMappingEngine} className="img-fluid" />
                    </Col>
                    <Col>
                        <h2 className="mb-4">
                            <FormattedMessage
                                id="landing.mapping_engine.title"
                                defaultMessage='<brand>JAYSQUARED</brand> Mapping Engine'
                            />
                        </h2>
                        <h3 className="m-0"><FormattedMessage id="landing.mapping_engine.high_performance.title" defaultMessage="Reliable performance" /></h3>
                        <p className="lead"><FormattedMessage id="landing.mapping_engine.high_performance.desc" defaultMessage="Developed over 12 years and tested in apps used by millions of users" /></p>
                        <h3 className="m-0"><FormattedMessage id="landing.mapping_engine.offline.title" defaultMessage="Offline" /></h3>
                        <p className="lead"><FormattedMessage id="landing.mapping_engine.offline.desc" defaultMessage="No internet connection necessary, available everywhere" /></p>
                        <h3 className="m-0"><FormattedMessage id="landing.mapping_engine.customizable.title" defaultMessage="Fully Customizable" /></h3>
                        <p className="lead"><FormattedMessage id="landing.mapping_engine.customizable.desc" defaultMessage="Define country colors, border lines, terrain, ocean details, ..." /></p>

                    </Col>
                </Row>

            </Segment>
            <Segment size="lg">
                <Row className="justify-content-center mb-4">
                    <Col sm={12} md={11} lg={10} xl={9} xxl={8}>

                        <h2 className="text-center"><Link to="/apps/whereisthat">{intl.formatMessage({ id: "app.whereisthat.title" })}</Link></h2>
                        <h3 className="text-center"><FormattedMessage id="landing.whereisthat.subtitle" defaultMessage="A geography quiz for iOS and Android." /></h3>
                        <p className="lead text-center"><FormattedMessage id="landing.whereisthat.lead" defaultMessage="Downloaded by over 10 million users. Top chart positions. Featured by Apple." /></p>
                    </Col>
                </Row>

                <Device centered size="lg" device="ipad-pro" landscape screenshot={WhereIsThatScreeniPadMain} />

                <Row className="justify-content-center mt-5">
                    <Col lg={8}>
                        <p className="text-center">
                            <FormattedMessage id="landing.whereisthat.desc" defaultMessage="One of the top geography e-learning apps to learn countries, capitals, states, cities and much more with a scientifially proven algorithm. With the integrated online multiplayer, you can play with your friends all over the world." />
                            {' '}
                            <Link to="/apps/whereisthat/"><FormattedMessage id="button.more_infos" defaultMessage="More Infos" /></Link>

                        </p>
                        <p className="lead text-center">
                            <FormattedMessage id="landing.whereisthat.cta" defaultMessage="Download and try for free. Available on iOS and Android." />
                        </p>
                    </Col>
                </Row>
                <Row className="download-buttons justify-content-center">
                    <Col xs="auto">
                        <a href="http://apple.co/1s4yWSp"><img src={AppstoreButton} height={50} /></a>
                    </Col>
                    <Col xs="auto">
                        <a href="http://bit.ly/2reaEGk"><img src={GooglePlayButton} height={50} /></a>
                    </Col>
                </Row>

            </Segment>
            <Segment background="primary">
                <Row className="justify-content-center mb-5">
                    <Col sm={12} md={11} lg={10} xl={9} xxl={8}>


                        <h1 className="text-center"><a href="https://events.jaysquared.com"><FormattedMessage id="app.jaysquaredevents.title" defaultMessage="Jaysquared Events" /></a></h1>
                        <h3 className="text-center"><FormattedMessage id="landing.jaysquaredevents.subtitle" defaultMessage="White label solution for your live quiz." /></h3>
                        <p className="lead text-center "><FormattedMessage id="landing.jaysquaredevents.lead" defaultMessage="Geography or multiple choice quiz. Built-in and custom questions. Individual corporate design." /></p>
                    </Col></Row>
                <img src={EventsMockup} className="img-fluid" />

                <Row className="justify-content-center mt-5">
                    <Col sm={12} md={11} lg={10} xl={9} xxl={8}>
                        <p className="text-center"><FormattedMessage id="landing.jaysquaredevents.highlights_1" defaultMessage="White label quiz solution for events, exhibitions, teambuilding exercises, office parties and more. Host live multiple choice and geography quizzes with multiple players. Fully customizable with colors, fonts, logo and your own questions." /></p>
                        <p className="lead text-center"><FormattedMessage id="landing.jaysquaredevents.cta" defaultMessage="Contact me now for a demo and to discuss your requirements." /></p>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col xs={12} sm={6} md={5} lg={4} className="pb-1">
                        <Button color='primary' size='lg' block>info@jaysquared.com</Button>
                    </Col>
                    <Col xs={12} sm={6} md={5} lg={4}>
                        <a href="https://events.jaysquared.com"><Button color='primary' size='lg' block><FormattedMessage id="button.more_infos" defaultMessage="More Info" /></Button></a>
                    </Col>
                </Row>


            </Segment>

            <Segment>
                <Row className="justify-content-center mb-5">
                    <Col sm={12} md={11} lg={10} xl={9} xxl={8}>
                        <h1 className="text-center text-hand"><Link to="/apps/events"><FormattedMessage id="app.weddingduel.title" defaultMessage="WeddingDuel" /></Link></h1>
                        <h3 className="text-center"><FormattedMessage id="landing.weddingduel.subtitle" defaultMessage="Individual live quiz for your wedding." /></h3>
                        <p className="lead text-center "><FormattedMessage id="landing.weddingduel.lead" defaultMessage="Highly engaging competition between the bridal parties." /></p>
                    </Col></Row>
                <Row className="justify-content-center">
                    <Col xs="auto">
                        <img src={WeddingDuelMockup} className="img-fluid" />
                    </Col>
                </Row>

                <Row className="justify-content-center mt-5">
                    <Col sm={12} md={11} lg={10} xl={9} xxl={8}>
                        <p className="text-center"><FormattedMessage id="landing.weddingduel.desc" defaultMessage="Integrate this fun and engaging element into your wedding. Quickly generate a personal quiz with a mix of map, multiple choice or estimation questions by answering a questionnaire." /></p>
                        <p className="lead text-center"><FormattedMessage id="landing.weddingduel.cta" defaultMessage="WeddingDuel is coming soon. Sign up for updates." /></p>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col xs={11} sm={9} md={7} lg={6} xl={5} xxl={4}>
                        <NewsletterSignup id="weddingduel" />
                    </Col>
                </Row>


            </Segment>
            {/*<Segment background="honua">

                < Row className="align-items-center justify-content-center">

            <Col xs={12} md={8}>
                <h1><Link to="/apps/honua">{intl.formatMessage({ id: "app.honua.title" })}</Link></h1>
                <p className="highlights"><Markdown>{intl.formatMessage({ id: "page.index.honua.highlights_1" })}</Markdown></p>
                <p className="highlights">{intl.formatMessage({ id: "page.index.honua.highlights_2" })}</p>
                <Row className="download-buttons">
                    <Col xs="auto" className="mb-2">
                        <a href="https://apple.co/2QSuF77"><img src={AppstoreButton} height={50} /></a>
                    </Col>
                </Row>
            </Col>
            <Col xs="auto" md={4} className="py-5 text-center">

                <Device centered>
                    <img src={HonuaScreen1DE} className="img-fluid" />
                </Device>
            </Col>

        </Row>
    </Segment >*/}

            <Segment background="primary" size="md" >
                <h2 className="text-center mb-5"><FormattedMessage id="landing.jaysquared.title" defaultMessage="Who is Jaysquared?" /></h2>

                <Row className="justify-content-center">
                    <Col sm={12} md={11} lg={10} xl={9} xxl={8}>
                        <h3><FormattedMessage id="landing.jaysquared.intro" defaultMessage="Hi I'm Jonathan," /></h3>
                        <p className="lead"><FormattedMessage id="landing.jaysquared.text_1" defaultMessage="I started developing iPhone apps shortly after Apple released the App Store, spending most of my weekends and after work experimenting with new app ideas. The first version of Where is that? was released at the beginning of 2012 and it instantly hit the top of the German App Store charts. Six months later I quit my full time job in investment banking and went on my journey as an indie app developer." /></p>
                        <p className="lead"><FormattedMessage id="landing.jaysquared.text_2" defaultMessage="The app evolved into a full fledged learning platform and multiplayer geography game. I developed my own mapping engine, optimized for games, and this engine powers all of my geography games today. Where is that? reached numerous top chart positions worldwide, was prominently featured by Apple and is a certified learning platform in multiple countries." /></p>
                        <p className="lead"><FormattedMessage id="landing.jaysquared.text_3" defaultMessage="My software development experience reaches back to 1998, when I developed and operated a homepage builder with 25.000 users and worked part-time at SAP during high school. While studying business administration at university I had the chance to experience the world of investment banking at Dresdner Kleinwort, Goldman Sachs and Commerzbank." /></p>
                        <p className="lead"><FormattedMessage id="landing.jaysquared.text_4" defaultMessage="But ultimately I followed my passion of creating great products for users all around the world. Send me a message if you are interested in any of my apps or want to utilize my technology in a custom project." /></p>

                        <div className="py-4 text-center">
                            <SocialButtons />
                        </div>

                    </Col>
                </Row>
            </Segment >
            <Footer />
        </div >
    );
}
export default IndexPage;
