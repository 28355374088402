import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import moment from 'moment';
import { useIntl, Link } from "gatsby-plugin-intl"
import LanguageSwitch from "./LanguageSwitch"

const Footer = () => {

	const intl = useIntl()


	return (
		<div className="footer">
			<Container>
				<Row>
					<Col xs={12} sm={10}>
						<Row>
							<Col xs={12} md={4}>
								<ul>
									<li><Link to="/terms/service/website/en">{intl.formatMessage({ id: "page.termsofservice.title" })}</Link></li>
									<li><Link to="/terms/privacy/website/en">{intl.formatMessage({ id: "page.privacynotice.title" })}</Link></li>
									<li><Link to="/imprint">{intl.formatMessage({ id: "page.imprint.title" })}</Link></li>
								</ul>
							</Col>
							<Col xs={12} md={4}>
								<LanguageSwitch />
							</Col>
						</Row>
					</Col>
					<Col xs={12} sm={2} className="text-right">
						© {moment().format('Y')} Jaysquared

					</Col>
				</Row>
				<Row>
					<Col className="text-center">
						<small>{intl.formatMessage({ id: "footer.legal.text" })}</small>

					</Col>
				</Row>
			</Container>

		</div>
	);
}


export default Footer;
