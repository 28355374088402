import React, { useState } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Container
} from 'reactstrap';
//import { Link as RRNavLink } from 'gatsby';
import { useIntl, Link as RRNavLink } from "gatsby-plugin-intl"

import Icon from '../images/Icon.png';

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);
  const intl = useIntl()

  const toggle = () => setIsOpen(!isOpen);

  return (
    <Navbar expand="md" container>
      <NavbarBrand href="/">JAYSQUARED</NavbarBrand>
      <NavbarToggler right onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="ms-auto" navbar>
          <NavItem>
            <NavLink exact to="/" activeClassName="active" tag={RRNavLink}>{intl.formatMessage({ id: "page.index.title" })}</NavLink>
          </NavItem>
          <NavItem>
            <NavLink exact to="/apps/whereisthat" activeClassName="active" tag={RRNavLink}>{intl.formatMessage({ id: "app.whereisthat.title" })}</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="https://events.jaysquared.com" activeClassName="active">{intl.formatMessage({ id: "app.jaysquaredevents.title.short" })}</NavLink>
          </NavItem>
          {/*<NavItem>
            <NavLink exact to="/apps/honua" activeClassName="active" tag={RRNavLink}>{intl.formatMessage({ id: "app.honua.title" })}</NavLink>
  </NavItem>*/}
          <NavItem>
            <NavLink exact to="/imprint" activeClassName="active" tag={RRNavLink}>{intl.formatMessage({ id: "page.contact.title" })}</NavLink>
          </NavItem>
        </Nav>
      </Collapse>
    </Navbar>
  );
}


export default Navigation;
