//@flow
import * as React from "react"

import Markdown from 'markdown-to-jsx';
import breaks from 'remark-breaks';
import moment from "moment";

import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import Segment from '../components/Segment';
import SEO from '../components/seo';

type Props = {
	terms: any,
}

export default function Terms({ terms }: Props): React.Node {

	return (
		<Segment background="primary">
			<SEO title={terms.termsTitle} />
			<h2>{terms.termsTitle} for {terms.title}</h2>
			{terms.sections.map(({ sectionId, text, title, link, address }) => {
				return (<div key={sectionId}>
					{title && <h5 className="mt-3 mb-1">{title}</h5>}
					{/*<div className="text-justify mt-0 mb-1">{textParser(text)}</div>*/}
					{<div className="text-justify mt-0 mb-1"><Markdown options={{ forceBlock: true }}>{text}</Markdown></div>}
					{address && <p className="m-0">Data Controller: {address}</p>}
					{link && <p>{link}</p>}
				</div>)
			})}
		</Segment>
	)
}

const textParser = (text: ?string): any => {
	if (!text) {
		return '';
	}
	const parsedText = text.split('\n').map((item, key) => {
		return <span key={key}>{item}<br /></span>
	});

	return parsedText;
}
